.user-job-referrals-details-container {
  padding-left: 20px;
  background-color: #f6f6f6;
  width: 100%;
}
.user_dashboard_path {
  color: #717173 !important;
}
.user_dashboard_exact_path {
  color: #10824c !important;
}
.jra-header-heading {
  font-weight: 400;
}
.jra-status-label-colour{
  color: #717173;
}
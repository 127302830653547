.help-center-container {
  display: flex;
  flex-direction: column;
}
.help-center-search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.help-center-search-container > div:first-child {
  font-family: Lexend;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: #0b0b1b;
  width: 60%;
}

.help-center-search-container > div:nth-child(2) {
  position: relative;
  width: 60%;
  /* max-width: 400px; */
  padding-top: 20px;
}
.search-icon-help-center {
  position: absolute;
  top: 28px;
  height: 30px;
  color: white;
  padding: 0 12px;
  cursor: pointer;
}
.help-center-search-input {
  width: 100%;
  max-width: 800px;
  height: 45px;
  padding: 0 10px;
  border: 1.6px solid #cacacb;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: transparent;
  padding-left: 55px;
  border-radius: 8px;
}

.help-center-card-container {
  max-width:350px;
}

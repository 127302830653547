.main-learninghub-filter {
  display: flex;
  flex-direction: column;
  /* background-color: #0B0B1B; */
  background-color: #0b0b1b;
  /* background: linear-gradient(
    90deg,
    #1ad079 8%,
    #0b0b1b 20%,
    #1ad079 32%,
    #0b0b1b 44%,
    #1ad079 64%
  ); */
  padding: 50px 100px;
  border-radius: 20px;
}

.learninghub-filter-heading {
  font-family: Lexend;
  font-weight: 400;
  text-align: left;
  color: #cacacb;
}
.learninghub-filter-des {
  color: #cacacb;
  max-width: 600px;
}
.learninghub-filter-form-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
}
.learninghub-filter-form-input {
  background-color: transparent;
  border-radius: 24px;
  width: 220px;
  height: 40px;
  font-size: 16px;
  padding-left: 17px;
  font-family: Lexend;
  border: 2px solid #373741;
  color: #717173;

  
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}


.learninghub-filter-dropdown-container{

  position: relative;
  width: 220px;
  height: 40px;
}

.learninghub-dropdown-icon {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  pointer-events: none; 
}



.learninghub-search {
  width: 300px;
  padding-left: 50px;
  height: 36px;
}
.learninghub-search-container {
  position: relative;
  /* width: 60%; */
}
.learninghub-search-icon {
  position: absolute;
  top: 10px;
  height: 22px;
  color: white;
  padding: 0 18px;
  cursor: pointer;
}
.learninghub-filter-form-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.date-label {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #aaa;
  transition: all 0.2s;
}

.learninghub-filter-form-input {
  /* padding-left: 20px; */
  color: #717173;
}

.date-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: white; /* Set to white or any desired color */
}

.learninghub-filter-form-input:focus + .date-label,
.learninghub-filter-form-input:not(:placeholder-shown) + .date-label {
  visibility: hidden; /* Hide the label when input is focused or has a value */
}

.learninghub-filter-form-input::-webkit-calendar-picker-indicator {
  filter: invert(100%); /* Makes the calendar icon white in WebKit browsers */
}
select.learninghub-filter-form-input {
  background-color: transparent;
  color: #717173;
}

select.learninghub-filter-form-input option {
  background-color: #0b0b1b;
  color: #717173;
}

.learninghub-filter-date:not(:focus):not(:valid)::before {
  content: attr(data-placeholder);
  width: 100%;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  pointer-events: none;
}

@media (max-width: 1186px) {
  .main-learninghub-filter {
    padding: 50px 50px;
  }
  .learninghub-search {
    width: 200px;
  }
}

@media (max-width: 960px) {
  .learninghub-filter-form-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: 20px;
  }

  .learninghub-filter-dropdown-container {
    position: relative;
    max-width: 612px;
    width: 100%;
    
}

.learninghub-dropdown-icon {
  
  top: 66%;
  right: 19px;
}
  .learninghub-filter-form-input {
    width: 100%;
    height: 50px;
    flex-wrap: wrap;
    border-radius: 100px;
  }
  .main-learninghub-filter {
    align-items: center;
  }
  .learninghub-filter-des {
    text-align: center;
  }
  .learninghub-filter-date {
    width: 98%;
    border-radius: 100px;
  }
  .learninghub-search-container {
    width: 90%;
  }
  .learninghub-search-icon {
    top: 16px;
  }
}

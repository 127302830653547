.main-articleview-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.articleview-des-container {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  padding: 30px 60px;
  border-radius: 8px;
}
.articleview-img-container {
  width: 100%;
  height: 440px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
}

.articleview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.articleview-des {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;
  color: #373741;
}
.tag-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}
.pre-next-article-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  border-right: none;
  padding: 30px 0px;
  margin-top: 20px;
}
.pre-article-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 40%;
}
.pre-article-container > div:first-child {
  font-family: Lexend;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #717173;
}
.pre-article-container > :nth-child(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.pre-article-container > :nth-child(2) > div {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #0b0b1b;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* height: 2.4em;
  line-height: 1.2em; */
}

.webinar-des-container {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  padding: 0px;
}
.webinar-des-container > div {
  /* width: 50%; */
  padding-right: 10px;
}
.webinar-des-container > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  padding: 25px 0px 0px 25px;
  border-left: 1px solid; /* This sets the border width */
  border-image: linear-gradient(transparent 80px, #e7e7e7 10px) 1;
  gap: 8px;
}
.webinar-host-heading {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #0b0b1b;
  width: 200px;
  padding-top: 10px;
}
.webinar-host-container {
  display: flex;
  gap: 10px;
}
.host-details> div:first-child {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #3a37d6;
}
.host-details> div:nth-child(2) {
  font-family: Lexend;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #717173;
}
.host-img-container{
  width: 48px;
  height: 48px;
}
.webinar-host-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;
}

.webinar-points-container {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;
  color: #717173;
}
.webinarview-des {
  color: #717173;
}
@media (max-width: 768px) {
  .tag-container {
    justify-content: center;
    flex-direction: column;
  }
}

@media (max-width: 425px) {
  .articleview-des-container {
    padding: 10px 20px;
  }
  .pre-next-article-container {
    display: flex;
    flex-direction: column;
  }
  .pre-article-container {
    width: 100%;
  }
  .pre-next-article-container {
    gap: 25px;
  }

  /* webinarView */
  .webinar-des-container {
    display: flex;
    flex-direction: column-reverse;
    background-color: transparent;
    padding: 0px;
  }
  .webinar-des-container > div:nth-child(2) {
    padding: 0px;
    border: none;
  }
  .articleview-img-container {
    width: 100%;
    height: 300px;
  }
}

.account-setting-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.account-setting-container .setting-profile-pic {
  width: 98px;
  height: 98px;
  border-radius: 100px;
}
.account-setting-container .setting-des {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #959595;
  max-width: 650px;
  padding-bottom: 40px;
}
.account-setting-container .profile-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.account-setting-container .setting-details-container {
  display: flex;
  flex-direction: row;
  gap: 60px;
}
.setting-form-input {
  /* max-width: 300px; */
  width: 100%;
}
.setting-form-password-input {
  width: 280px;
}
.account-setting-hr {
  border: none;
  border-bottom: 2px solid #eeeeee;
  margin: 10px 0;
}
.account-setting-container .personal-information-password {
  display: flex;
  flex-direction: row;
  gap: 60px;
}
.heading-font-weight {
  font-weight: 400;
}
.user-setting-button {
  display: flex;
  width: 200px;
  justify-content: center;
}

@media (max-width: 768px) {
  .account-setting-container .setting-details-container {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .account-setting-container .personal-information-password {
    display: flex;
    flex-direction: row;
    gap: 0px;
  }
  .account-setting-container .profile-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  .account-setting-container .personal-information-password {
    display: flex;
    flex-direction: column;
  }
  .setting-form-password-input {
    max-width: 300px;
  }
}
@media (max-width: 425px) {
  .account-setting-container .profile-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  .contact-form-input {
    width: 95% !important;
  }
}

@media (max-width:768px) {
.setting-form-password-input {
  max-width:768px;
 width:100%;
}
.main-dashboard-container {
 padding-left:0px !important;
}
}
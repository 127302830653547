.main-landing-header-container {
  display: flex;
  background-color: #0b0b1b;
  width: 100%;
  justify-content: center;
}
.landing-header-container {
  display: flex;
  flex-direction: row;
  background-color: #0b0b1b;
  height: auto;
  align-items: center;
  padding-left: 30px;
  padding-right: 50px;
  padding-bottom: 30px;
  max-width: 1330px;
}
.landing-header-detail-container {
  display: flex;
  flex-direction: column;
  color: white;
  max-width: 600px;
  gap: 20px;
}
.landing-header-detail-container > div:first-child {
  font-size: 64px;
  font-weight: 700;
  /* line-height: 96px; */
  font-family: tektur;
  max-width: 700px;
}
.landing-header-detail-container > div:first-child h1 {
  text-align: left;
}
.heading-different-color {
  color: #1ad079;
}
.landing-header-des {
  color: #f8f8f8;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.landing-header-button-container {
  display: flex;
  flex-direction: row;
}
.landing-header-image {
  width: 50%;
}
@media (max-width: 768px) {
  .landing-header-container {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    padding-bottom: 30px;
     padding-left: 100px;
  padding-right: 50px;
    
  }
  .landing-header-image {
    width: 100%;
  }
  .landing-header-detail-container > div:first-child h1 {
    text-align: center;
  }
  .landing-header-des {
    text-align: center;
  }
  .landing-header-detail-container {
    align-items: center;
    padding: 0px 10px;
  }
  .landing-header-container {
    padding-left: 15px;
    padding-right: 15px;
}
}

@media (min-width: 1440px) {
  .landing-header-container {
    width: 100%;
    justify-content: space-around;
    max-width: 1440px;
  }
}
@media (max-width: 1440px) and (min-width:768px) {
  .landing-header-container {
    padding-left:100px;
  }
}
@media (max-width:768px){
  .landing-header-container {
    padding-left:0px;
    padding-right: 0px;
  }
}

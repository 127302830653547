.our-services-container-heading {
  font-family: Tektur;
  font-size: 42px;
  font-weight: 700;
  line-height: 72px;
  text-align: left;
  color: #0b0b1b;
}
.our-services-container-heading > span {
  color: #1ad079;
}
.our-services-container > p {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #717173;
}
.main-our-services-container {
  display: flex;
  flex-direction: column;
  gap: 80px;
}
.our-services-sub-container {
  font-family: Lexend;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  color: #282834;
}
.our-services-points-container {
  display: flex;
  flex-direction: column;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #717173;
  gap: 8px;
}
.benefit-point-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.our-services-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap:40px;
}
.our-services-container:nth-child(even) {
  flex-direction: row-reverse;
}
.our-services-container > div > div:nth-child(2) {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #717173;
}
.our-services-image {
  width:100%;
  height: auto;
}
.our-services-container > div {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: flex-start;
  width: 600px;
}
/* button style */
.services-button-common {
  padding: 5px 20px;
  margin: 3px;
  margin-right: 6px;
  width: max-content;
  height: 35px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
  transition: transform 0.3s;
  font-size: 16px;
  font-weight: 400;
  font-family: Lexend;
  background-color: #1ad079;
  border: none;
}
.services-button-common :hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}

@media (max-width: 768px) {
  .our-services-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
}
.our-services-container:nth-child(even) {
  flex-direction: column;
}
.our-services-container > div {
  width: 100%;
}
.our-services-container > div {
  align-items: center;
}
.our-services-container > div > div:nth-child(2) {
  text-align: center;
}
}
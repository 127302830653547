.discussion-profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding-top: 5px;
  padding-right: 14px;
}
.discussion-post-description-container {
  width: 100%;
}
.discussion-post-container {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 20px;
  width: 100%;
}
.discussion-post-des {
  color: #717173;
}
.post-comment-input {
  width: 100%;
  max-width: 800px;
  height: 45px;
  padding: 0 10px;
  border: 1.6px solid #cacacb;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 8px;
  margin-top: 10px;
}
.post-comment-input::placeholder {
  color: #cacacb;
}
.answers-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  padding: 10px;
  border-left: none !important;
  border-right: none !important;
  width: 100%;
}
.discussion-ans {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #717173;
  margin-top: 0px;
}
@media (max-width: 425px) {
  .comment-share-button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.post-info-card {
  max-width: 400px;
  margin: 0 auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #F4F4F4;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.view-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}
.user-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 20px;
}
.user-info > div:nth-child(2) {
  padding-top: 20px;
}
.post-user-email {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #717173;
}

.avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 16px;
}

.post-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-bottom: 20px;
}

.postby-heading {
  color: #0b0b1b;
  font-weight: 400;
  font-size: 14px;
}
.postby-des {
  color: #717173;
  font-size: 14px;
}

.bold {
  font-weight: bold;
}

.status span {
  background-color: #a9edcd;
  color: #10824c;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.875rem;
  border-radius: 20px;
  padding: 8px 20px;
}

.votes {
  margin-top: 16px;
}
.votes > div:first-child{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.vote-list > div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.vote-toggle {
  background: none;
  border: none;
  color: #000000;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin-left: 8px;
}

.arrow.down {
  border-top: 5px solid #000000;
}

.arrow.up {
  border-bottom: 5px solid #000000;
}

.vote-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.vote-list li {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.vote-list img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
}

.blue-text {
  color: #3a37d6;
}

.postby-user-name {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #373741;
}

.postby-user-img-container {
  width: 60px;
  height: 60px;
}
.postby-user-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;
}

.referee-container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 24px;
}

.referee-title {
  font-size: 24px;
  font-weight: 400;
  color: #10824c;
  margin-bottom: 32px;
}

.timeline-container {
  position: relative;
}

.timeline-line {
  position: absolute;
  left: 24px;
  top: 68px;
  bottom: 0;
  width: 4px;
  background-color: #e5e7eb;
  border-radius: 10px;
}

.timeline-item {
  position: relative;
  display: flex;
  gap: 24px;
  /* margin-bottom:10px; */
}


.timeline-item:last-child {
  margin-bottom: 0;
}

.icon-container {
  position: relative;
  padding-top: 10px;
}

.icon-wrapper {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.icon {
  width: 24px;
  height: 24px;
}

.icon-registration {
  background-color: #149c5b26;
}

.icon-registration .icon {
  color: #16a34a;
}

.icon-verification {
  background-color: #9747ff33;
}

.icon-verification .icon {
  color: #9333ea;
}

.icon-earning {
  background-color: #71717333;
}

.icon-earning .icon {
  color: #4b5563;
}

.icon path{
  fill: #16a34a; 
}

.content-wrapper {
  flex: 1;
}

.content-card {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 20px;
}

.section-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 12px;
  color: #0b0b1b;
}

.section-text {
  color: #373741;
  line-height: 1.5;
  margin-bottom: 16px;
  font-weight: 400;
}

.verify-button {
  background-color: #16a34a;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.verify-button:hover {
  background-color: #15803d;
}

.earning-list {
  /* list-style: none; */
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-left: 18px;
}
.earning-item {
  color: #4b5563;
  line-height: 1.5;
}

.earning-label {
  font-weight: 600;
  color: #111827;
}
.referee-verification-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.referee-verification-des {
  color: #717173;
  font-size: 16px;
  max-width: 400px;
  width: 100%;
  text-align: center;
}
.referee-verification-heading {
  color: #9747ff;
  font-weight: 500;
  padding-bottom: 15px;
}
.referee-verification-button {
  border: none;
  background-color: #9747ff;
  color: white !important;
}
.referee-verification-heading-red {
  color: #ff6b5a;
  font-weight: 500;
  padding-bottom: 15px;
}
.referee-verification-button-red {
  border: none;
  background-color: #ff6b5a;
  color: white !important;
}
.referee-verification-rejected-des {
  max-width: 740px;
}
.referee-verification-rejected-button-container {
  display: flex;
  gap: 30px;
}
.referee-verification-rejected-button-icon {
  padding-right: 8px;
}
.referee-verification-button-width {
  width: 205px;
  display: flex;
}
/* Responsive styles */
@media (max-width: 640px) {
  .timeline-item {
    gap: 16px;
  }

  .icon-wrapper {
    width: 40px;
    height: 40px;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .timeline-line {
    left: 20px;
  }

  .section-title {
    font-size: 18px;
  }
}

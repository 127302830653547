/* Chat Container */
.chat-app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.chat-app-message-container{
    display: flex;
    flex-direction: column;
}
/* Messages Container */
.chat-app-messages {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  background-color: #f3f4f6;
}

/* Message Styles */
.chat-app-message {
  display: flex;
  margin-bottom: 16px;
  gap: 10px;
}

.chat-avatar-container {
  width: 48px;
  height: 48px;
}

.chat-app-message.received {
  justify-content: flex-end;
}

.chat-app-message-content {
  max-width: 70%;
  padding: 12px;
  border-radius: 8px;
  font-size: 14px;
}

.chat-app-message.received .chat-app-message-content {
  background-color: #e0f2fe;
  margin-left: 8px;
}

.chat-app-message.sent .chat-app-message-content {
  background-color: #dcfce7;
  margin-right: 8px;
}

.chat-app-message-time {
  font-size: 12px;
  color: #6b7280;
  margin-top: 4px;
}

/* Input Area */
.chat-app-input-container {
  padding: 16px;
  background-color: #ffffff;
  border-top: 1px solid #e5e7eb;
}

.chat-app-input-wrapper {
  display: flex;
  align-items: center;
}

.chat-app-text-input {
  flex: 1;
  padding: 12px;
  border: 1px solid #e5e7eb;
  border-radius: 6px 0 0 6px;
  font-size: 14px;
}

.chat-app-text-input:focus {
  outline: none;
  border-color: #10b981;
}

.chat-app-attachment-btn {
  padding: 12px;
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-left: none;
  cursor: pointer;
}

.chat-app-send-btn {
  padding: 12px 16px;
  background-color: #10b981;
  color: white;
  border: none;
  border-radius: 0 6px 6px 0;
  cursor: pointer;
}

/* Utility Classes */
.chat-app-icon {
  width: 20px;
  height: 20px;
}

/* Responsive Adjustments */
@media (max-width: 640px) {
  .chat-app-header {
    padding: 12px;
  }

  .chat-app-message-content {
    max-width: 85%;
  }

  .chat-app-buttons {
    flex-direction: column;
  }
}

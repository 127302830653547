/* .main-learninghub-container{
    display: flex;
    flex-direction: column;
} */
.main-learninghub-container > div:first-child {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1%;
}
.learninghub-container {
  width: 30%;
  margin-bottom: 20px;
  box-sizing: border-box;
}
.learninghub-image {
  border-radius: 10px;
  width: 100%;
}
.learninghub-container p {
  color: #717173;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 2.4em;
  line-height: 1.2em;
}
.learninghub-title-container {
  height: 72px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* height: 2.4em; */
  line-height: 1.2em;
  transition: height 0.3s ease-in-out;
}
/* .learninghub-title-container:hover {
  height: auto; 
  -webkit-line-clamp: unset; 
  overflow: visible; 
} */
.learninghub-name-date-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.learninghub-name-date-container {
  font-family: Lexend;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  padding: 5px 0px;
  width: 100%;
}
.learninghub-name-date-container > div:first-child {
  color: #3a37d6;
}
.learninghub-name-date-container > div:nth-child(2) {
  color: #717173;
}

/* .pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
  gap: 30px;
}

.pagination button {
  margin: 0 5px;
  padding: 10px 15px;
  border: none;
  background-color: #e7e7e7;
  cursor: pointer;
  border-radius: 8px;
  font-size: 16px;
  color: #717173;
}

.pagination button.active {
  background-color: #1ad079;
  color: #0b0b1b;
}

.pagination button:hover:not(.active) {
  background-color: #ddd;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.pagination-arrow-button {
  margin: 0 5px;
  padding: 10px;
}
.previous-next-button-icon {
  font-size: 10px;
  color: #717173;
}
.previous-next-button-img {
  width: 15px;
  height: auto;
} */

@media (max-width: 768px) {
  .learninghub-container {
    width: 49%;
    margin-bottom: 20px;
  }
  /* .pagination button {
    padding: 10px;
    font-size: 12px;
  }
  .previous-next-button-img {
    width: 12px;
    height: auto;
  } */
}
@media (max-width: 425px) {
  .main-learninghub-container {
    display: flex;
    flex-direction: column;
  }
  .learninghub-container {
    width: 100%;
    margin-bottom: 20px;
  }
  .learninghub-title-container {
    height: auto;
  }
  .learninghub-title-container {
    height: auto;
    -webkit-line-clamp: unset;
    overflow: visible;
  }
  /* .pagination {
    gap: 5px;
  } */
}

.ai-review-container{
  display: flex;
  flex-direction: column;
}

.feedback-ai-input-container{
  width: 100%;
  height: 200px;
  background-color: transparent;
}
.cv-consultant-review-child {
  display: flex;
  gap: 20px;
  align-items: center;
}

@media (max-width:630px) {
  .jra-heading-container {
    display: flex;
    flex-direction: column;
}
.jra-save-button {
  margin-left: 13px;
}
.cv-consultant-review-child {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
}

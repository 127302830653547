.dashboard-container {
    background-color: white;
}
.main-dashboard-container {
  padding-left: 20px;
  background-color: rgb(255, 255, 255);
  width: 100%;
}
.setting-page-container {
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
}

.sub-setting-page-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  padding: 30px;
  /* margin-top: 20px; */
}

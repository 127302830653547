/* styles.css */
.jra-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f6f6f6;
}

.green-heading {
  color: #149c5b;
}

.jra-heading {
  padding-left: 15px;
  padding-bottom: 5px;
  font-weight: 500;
}

.jra-header-title-icon {
  width: 10px;
  padding-right: 10px;
}

.chatIcon {
  padding: 5px;
}

.jra-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}

.jra-header-title {
  display: flex;
  align-items: center;
}

.jra-header-title h5 {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 600;
}

.jra-header-actions {
  display: flex;
  align-items: center;
}

.jra-status-select {
  margin-right: 16px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.jra-chat-button {
  display: flex;
  align-items: center;
  background-color: #000;
  color: white;
  padding: 3px 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.jra-notification-badge {
  background-color: #ef4444;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-left: 8px;
}

.jra-card {
  background-color: #fafafa;
  border-radius: 10px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  border: 1px solid #f1f1f1;
  padding: 24px;
  margin-bottom: 32px;
}

.jra-card h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
}

.jra-status-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.jra-status-row>div:nth-child(2) {
  display: flex;
  gap: 80px;
}

.jra-status-row>div:nth-child(2)>div:nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.jra-status-label {
  color: #0b0b1b;
  font-size: 16px;
  font-weight: 400;
  font-family: Lexend;
  line-height: 24px;
}

.jra-status-value {
  font-size: 16px;
  font-weight: 400;
  color: #717173;
  line-height: 24px;
}

.jra-status-badge {
  background-color: #22c55e;
  color: white;
  padding: 4px 12px;
  border-radius: 9999px;
  font-size: 14px;
}

.jra-grid-2-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  align-items: center;
}

.jra-file-link {
  display: flex;
  align-items: center;
  gap: 15px;
  color: #717173;
}

.jra-file-icon {
  margin-right: 8px;
}

.jra-pdf-link {
  color: #ef4444;
}

.jra-doc-link {
  color: #3b82f6;
}

.jra-table-container {
  overflow-x: auto;
}

.jra-table {
  width: 100%;
  border-collapse: collapse;
}

.jra-table th {
  text-align: left;
  padding-bottom: 8px;
}

.jra-table td {
  padding: 8px 0;
}

.jra-amount-cell {
  text-align: right;
}

.jra-timeline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.jra-timeline-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 15px;
  padding: 15px 3px;
  width: 110px;
  justify-content: center;
}

.jra-timeline-dot {
  width: 32px;
  height: 32px;
  background-color: #22c55e;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-bottom: 8px;
}

.jra-timeline-label {
  font-weight: 400;
  padding-top: 10px;
  color: #0b0b1b;
  text-align: center;
  font-size: 14px;
}

.jra-timeline-date {
  font-weight: 400;
  padding-top: 10px;
  color: #717173;
  text-align: center;
  font-size: 14px;
}

.jra-timeline-hr {
  border: none;
  height: 6px;
  background-color: #149c5b;
  margin: 20px 0;
  max-width: 70px;
  width: 100%;
  opacity: 0.8;
  border-radius: 50px;
}

.payment-container {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 20px;
  background-color: #ffffff;
  max-width: 500px;
  margin: 20px auto;
}

.payment-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333333;
}

.payment-received-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
}

.payment-subtitle {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #555555;
}

.payment-received-details div:nth-child(even) {
  background-color: #ffffff;
  border: 1px solid #f4f4f4;
}

.payment-received-details div:nth-child(odd) {
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
}

.payment-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: 1px solid #f0f0f0;
  font-size: 16px;
  font-weight: 400;
  border-radius: 5px;
}

.payment-row:last-child {
  border-bottom: none;
}

.payment-label {
  font-size: 16px;
  font-weight: 500;
  color: #888888;
}

.payment-value {
  font-size: 16px;
  font-weight: 400;
  color: #0b0b1b;
}

@media (max-width: 768px) {
  .jra-grid-2-col {
    grid-template-columns: 1fr;
  }

  .jra-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .jra-header-actions {
    margin-top: 16px;
  }

  .jra-timeline {
    flex-direction: column;
  }

  .jra-timeline-item {
    background-color: #f4f4f4;
    border-radius: 15px;
    margin-bottom: 16px;
  }
}

@media (max-width: 625px) {
  .jra-status-row {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 425px) {
  .payment-row {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 375px) {
  .jra-status-row>div:nth-child(2) {
    display: flex;
    gap: 0px;
    flex-direction: column;
  }

  .jra-status-row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .jra-status-value {
    text-align: center;
  }

  .jra-status-label {
    text-align: center;
  }

  .jra-heading {
    text-align: center;
  }

  .jra-header {
    flex-direction: column;
    align-items: center;
  }

  .jra-header-heading {
    text-align: center;
  }

  .user-job-referrals-details-container {
    padding-left: 0px !important;
  }

  .jra-file-link {
    display: flex;
    justify-content: center;
  }
}
.path-container {
  display: flex;
  /* margin-top: 52px; */
  /* margin-left: 48px; */
  flex-direction: row;
  justify-content: flex-start;
  color: var(--title-path);
  cursor: pointer;
  padding-bottom: 20px;
  flex-wrap: wrap;
}

.path-container div {
  font-size: var(--font-size-base);
  color: var(--title-path);
  padding-left: 4px;
}

@media (max-width:425px) {
  .path-container {
    padding-top: 10px;
    padding-bottom: 0px;
  }
}
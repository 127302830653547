.dt-container {
  width: 100%;
  overflow-x: auto;
  background-color: white;
}

.dt-table {
  width: 100%;
  border-collapse: collapse;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    sans-serif;
}

.dt-header-cell {
  padding: 12px 16px;
  text-align: left;
  background-color: #f9fafb;
  color: #374151;
  font-weight: 600;
  border-bottom: 1px solid #e5e7eb;
}

.dt-checkbox-cell {
  width: 40px;
  text-align: center;
}

.dt-cell {
  padding: 12px 16px;
  border-bottom: 1px solid #e5e7eb;
  color: #1f2937;
}

.dt-badge {
  padding: 3px 15px;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 500;
  width: 50px;
  display: flex;
  justify-content: center;
}

/* Status badges */
.dt-badge.status-published {
  background-color: #a9edcd;
  color: #10824c;
}

.dt-badge.status-draft {
  background-color: #2828341a;
  color: #282834;
}

.dt-badge.status-restricted {
  background-color: #ffe5e5;
  color: #bf5044;
}

.dt-badge.status-pending {
  background-color: #9747ff;
  color: #ffffff;
}
.dt-badge.status-rejected {
  background-color: #ff6b5a;
  color: #ffffff;
}
.dt-badge.status-processing {
  background-color: #1e90ff;
  color: #ffffff;
}
.dt-badge.status-approved {
  background-color: #149c5b;
  color: #ffffff;
}
.dt-badge.status-completed {
  background-color: #149c5b;
  color: #ffffff;
}

/* Category badges */
.dt-badge.category-article {
  background-color: #3a37d61a;
  color: #3a37d6;
}

.dt-badge.category-webinar {
  background-color: #9747ff1a;
  color: #9747ff;
}

.dt-action-buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
}

.dt-action-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
}

.dt-icon {
  width: 20px;
  height: 20px;
}

.dt-icon.delete {
  color: #ef4444;
}

.dt-icon.edit {
  color: #3b82f6;
}

.dt-sort-icon {
  margin-left: 4px;
  font-size: 10px;
}

/* user-dashboard-css */
.user-dashboard-table-heading {
  font-weight: 500;
  color: #149c5b;
}
.user-table-search {
  border-radius: 100px;
  width: 300px !important;
}
.user-dashboard-table-search-container {
  border-top-left-radius: 10px; 
  border-top-right-radius: 10px; 
}
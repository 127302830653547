.blog-post-table {
  font-family: Arial, sans-serif;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e2e8f0;
}
td {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #373741;
}
th {
  background-color: #f8fafc;
  font-weight: bold;
  color: #4a5568;
  font-family: Lexend;
  font-size: 14px;
  font-weight: 600;
}

.status {
  padding: 5px 10px;
  border-radius: 20px;
  font-family: Lexend;
  font-size: 12px;
  font-weight: 500;
}

.status.published {
  background-color: #a9edcd;
  color: #10824c;
}

.status.restricted {
  background-color: #fee2e2;
  color: #991b1b;
}

.action-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  margin-right: 5px;
}

.action-btn:hover {
  background-color: #f1f5f9;
  border-radius: 9999px;
}

.action-btn.delete {
  color: #ef4444;
}

.action-btn.edit {
  color: #3b82f6;
}
.table-button-container {
  display: flex;
  flex-direction: row;
}

/* search-filter-css */
.search-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 20px;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  background-color: white;
}
.table-search-input {
  max-width: 400px;
  width: 100%;
  height: 35px;
  border-radius: 8px;
  padding-left: 30px;
}
.table-search-input::placeholder {
  color: #cacacb;
  /* padding-left: 30px; */
  font-size: 15px;
}

.fa-magnifying-glass {
  color: #cacacb;
  position: absolute;
  padding: 12px;
}

.filter-sort-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #717173;
  border: 1px solid #cacacb;
  background-color: white;
  width: 110px;
  height: 35px;
  border-radius: 8px;
  font-size: 15px;
}

.search-filter-container > div:nth-child(2) {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.filter-sort-button-icon {
  width: 18px;
  height: auto;
  padding-right: 5px;
}


/* table */
.dt-container {
  width: 100%;
  overflow-x: auto;
}

.dt-table {
  width: 100%;
  border-collapse: collapse;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.dt-header-cell {
  padding: 12px 16px;
  text-align: left;
  background-color: #f9fafb;
  color: #374151;
  font-weight: 600;
  border-bottom: 1px solid #e5e7eb;
}

.dt-checkbox-cell {
  width: 40px;
  text-align: center;
}

.dt-cell {
  padding: 12px 16px;
  border-bottom: 1px solid #e5e7eb;
  color: #1f2937;
}

.dt-status-badge {
  padding: 4px 8px;
  border-radius: 9999px;
  font-size: 12px;
  font-weight: 500;
}

.dt-status-badge.pending {
  background-color: #e0e7ff;
  color: #4f46e5;
}

.dt-status-badge.approved {
  background-color: #d1fae5;
  color: #059669;
}

.dt-status-badge.rejected {
  background-color: #fee2e2;
  color: #dc2626;
}

.dt-status-badge.processing {
  background-color: #dbeafe;
  color: #2563eb;
}

.dt-status-badge.published {
  background-color: #d1fae5;
  color: #059669;
}

.dt-status-badge.draft {
  background-color: #f3f4f6;
  color: #4b5563;
}

.dt-status-badge.restricted {
  background-color: #fee2e2;
  color: #dc2626;
}

.dt-action-buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.dt-action-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
}

.dt-action-btn.delete {
  color: #dc2626;
}

.dt-action-btn.edit {
  color: #2563eb;
}

.dt-sort-icon {
  margin-left: 4px;
  font-size: 10px;
}
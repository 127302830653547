.dashboard-subnav-container {
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-left: 30px;
}

.subnav-item {
  cursor: pointer;
  color: #717173;
  font-family: Lexend;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  padding-bottom: 10px;
}

.subnav-item.active {
  color: #10824c;
  border-bottom: 5px solid #1ad079;
}

.subnav-hr {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border: none;
  height: 2px;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

/* .subnav-hr.active {
  background-color: red;
} */

.dashboard-learninghub-img-container {
  display: flex;
  flex-direction: row;
  height: 380px;
  gap:15px;
}
.dashboard-learninghub-img-container > div:first-child {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
}

.dashboard-learninghub-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dashboard-learninghub-img-container > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  padding: 15px 8px;
  background-color: #f8f8f8;
  border-radius: 15px;
}

.button-with-icon-green {
  width: 100px;
  height: 36px;
  border-radius: 20px;
  background-color: #1ad079;
  border: none;
  color: #0b0b1b;
  font-family: Lexend;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}
.button-icon {
  padding-right: 6px;
}

.resume-file-upload-container {
  border-radius: 8px;
}

.resume-file-upload-container h2 {
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}
.resume-file-heading {
  font-weight: 400;
  font-size: 16px;
  color: black !important;
}
.upload-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 300px;
  border: 1.6px dashed #cacacb;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}

.file-input-container {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
}

.file-input {
  display: none;
}

.file-label {
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.file-label:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.file-name {
  margin-left: 10px;
  color: #CACACB;
  font-size: 14px;
  text-align: center;
}

.error-message {
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
}

.file-details {
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
}

.file-details p {
  margin: 5px 0;
  color: #666;
}

.upload-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  margin-top: 10px;
}

.upload-button:hover:not(:disabled) {
  background-color: #218838;
}

.upload-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* @media (max-width: 600px) {
  .file-upload-container {
    margin: 10px;
    padding: 15px;
  }

  .file-input-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .file-name {
    margin-left: 0;
    margin-top: 5px;
  }
} */

/* Animation for hover states */
.file-label,
.upload-button {
  position: relative;
  overflow: hidden;
}

.file-label:after,
.upload-button:after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255, 255, 255, 0.2) 50%,
    transparent 100%
  );
  transition: left 0.3s;
}

.file-label:hover:after,
.upload-button:hover:after {
  left: 100%;
}

.about-us-container {
  display: flex;
  flex-direction: column;
}
.about-us-heading {
  font-family: Tektur;
  font-size: 48px;
  font-weight: 700;
  /* line-height: 72px; */
  text-align: left;
}
.about-us-heading p {
  color: #cacacb;
  font-size: 15px;
  text-align: left;
}
.main-mission-vission-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}
.mission-vission-icon {
  padding: 20px 10px 10px 20px;
  width: 60px;
  height: auto;
  border-radius: 10px;
  background: linear-gradient(to bottom, #373741, #282834);
}
.mission-vission-container {
  display: flex;
  flex-direction: column;
  max-width: 530px;
  border: 2px solid transparent;
  border-radius: 20px;
  transition: border-color 0.3s ease;
  box-shadow: 0 8px 10px rgba(11, 11, 27, 0.2);
  background-color: #f4f4f4;
}
.mission-vission-container > div {
  display: flex;
  flex-direction: row;
  padding: 25px;
  gap: 20px;
  height: 100%;
}
.mission-vission-container > div > div {
  width: 100%;
}
.mission-vission-container > div > div > div:first-child {
  font-family: Lexend;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: #282834;
}
.mission-vission-container > div > div > div:nth-child(2) {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #717173;
}
/* guiding-principles */
.guiding-principles-container {
  display: flex;
  flex-direction: column;
  gap: 35px;
}
.main-principles-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

@media (max-width: 1024px) and (min-width: 769px) {
  .main-principles-container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .sub-services-container {
    display: flex;
    flex-direction: column;
    max-width: 400px;
  }
}

@media (max-width: 768px) {
  .main-mission-vission-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
  }
  .about-us-heading {
    text-align: center;
  }
  /* .main-mission-vission-container p {
    text-align: center;
  } */
  .about-us-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about-us-container p {
    text-align: center;
  }
  .main-principles-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
}

.main-discussions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.main-discussions-container > div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  padding: 10px;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  padding-right: 20px;
}
.sub-discussions-container {
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-bottom: 1px solid #e7e7e7;
}

.recently-visited-img-container {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
}
.recently-visited-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.discussions-details-container {
  max-width: 65%;
}
/* .discussions-details-container>div:first-child{
  width:60%;
} */
.discussion-des {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.discussions-number-container {
  width: 50px;
  padding: 0px 10px;
}
.discussion-des-color {
  color: #717173;
}
.discussions-number-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25px;
}
.discussions-number-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #717173;
}
.comment-share-button-container {
  display: flex;
  flex-direction: row;
}
.up-down-arrow {
}
.discussions-img-container {
  width: 160px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
}
.discussions-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 1024px) and (min-width: 768px) {
  .main-home-container {
    padding: 10px 80px 10px 80px;
  }
}
@media (max-width: 1024px) {
  .main-discussion-form-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .discussion-fourm-filter-container {
    width: 100%;
  }
  .main-discussions-container {
    width: 100%;
  }
  .discussion-fourm-filter-container > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width:600px) {
  .main-discussions-container > div {
    width:100%;
  }
}

@media (max-width: 555px) {
  .sub-discussions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .discussions-img-container {
    width: 100% !important;
    height: 160px !important;
  }
  .discussions-number-container {
    display: flex;
    flex-direction:row;
    align-items: center;
    height: 40px;
  }
  .recently-visited-img-container {
    height: 180px;
  }
  .discussion-fourm-filter-container {
    padding: 0px;
  }
  .discussions-details-container {
    max-width: 100%;
  }
}

@media (max-width: 320px) {
  .discussion-button-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.main-konect-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.konect-signin-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 565px;
  border-radius: 20px;
}

.create-password-modal {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 565px;
  border-radius: 20px;
  gap: 33px;
}

.konect-submit:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.otp-v-resend-btn:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
.timer-text-time-color{
  color: red;
}
.signin-modal-img {
  height: 100%;
  object-fit: cover;
  object-position: right;
}

.konect-signin-container>div:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 15px;
  padding-left: 15px;
}

.konect-form-row>div {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
}

/* .konect-modal-overlay {
  position: fixed;
  top: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  max-width: 880px;
  width: 100%;
  height: 500px;
} */
.konect-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.konect-modal-content {
  background-color: white;
  border-radius: 20px;
  max-width: 900px;
  width: 100%;
}

.konect-modal-close {
  position: absolute;
  top: 0px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

.konect-modal-title {
  font-size: 24px;
  margin-bottom: 8px;
  color: #333;
}

.konect-modal-subtitle {
  font-size: 14px;
  color: #666;
  margin-bottom: 24px;
  text-align: center;
}

.konect-form {
  display: flex;
  flex-direction: column;
}

.konect-form-row {
  display: flex;
  gap: 20px;
}

.konect-input,
.konect-select {
  flex: 1 1;
  height: 0px;
  border: none !important;
  border-radius: 4px;
  font-size: 16px;
  width: 98% !important;
  padding-top: 10px;
}

.konect-input::placeholder {
  color: rgb(0, 0, 0) !important;
}

.konect-submit {
  border-radius: 10px;
  background-color: #149c5b;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  max-width: 400px;
  width: 100%;
  height: 50px;
  margin-top: 10px;
}

.konect-submit:hover {
  background-color: #1ad079b2;
}

.konect-divider {
  text-align: center;
  margin: 16px 0;
  color: #666;
  position: relative;
}

.konect-divider::before,
.konect-divider::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 40%;
  height: 1px;
  background-color: #ccc;
}

.konect-divider::before {
  left: 0;
}

.konect-divider::after {
  right: 0;
}

.konect-social-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.konect-social-buttons>div {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 400px;
}

.konect-social-button {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  display: flex;
  justify-content: space-evenly;
  width: 120px;
}

.konect-social-button:hover {
  background-color: #f0f0f0;
}

.konect-signin-prompt {
  text-align: center;
  margin-top: 16px;
  font-size: 14px;
  color: #717173;
}

.konect-signin-link {
  color: #059669;
  text-decoration: none;
}

.konect-signin-link:hover {
  text-decoration: underline;
}

.signin-input-label {
  font-size: 14px;
  font-weight: 400;
  color: #149c5b;
  margin-bottom: -8px;
  z-index: 2;
  padding: 0 5px;
  display: inline-block;
  white-space: nowrap;
}

.signin-input-label>div {
  background-color: white;
}

.signin-input-fieldset {
  display: flex;
  height: 38px;
  border-radius: 12px;
  border: 1.8px solid #e7e7e7;
  align-items: flex-start;
  flex-direction: column;
  width: 220px;
  justify-content: center;
}

.signin-heading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signin-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sign-modal-socialmedia-icon {
  width: 20px;
  height: auto;
}

@media (max-width: 600px) {
  .konect-form-row {
    flex-direction: column;
  }

  .konect-social-buttons>div {
    flex-direction: column;
  }

  .signin-input-fieldset {
    width: 100%;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* OTP screen css */
.otp-v-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9fafb;
}

.otp-v-card {
  background: white;
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.otp-v-header {
  text-align: center;
  margin-bottom: 32px;
}

.otp-v-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #1f2937;
}

.otp-v-subtitle {
  color: #6b7280;
  margin-bottom: 4px;
}

.modal-subtitle-margin {
  margin-bottom: 0px;
}

.otp-v-email-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #5d5d5d;
  font-weight: 600;
}

.otp-v-email {
  color: #6b7280;
}

.otp-v-edit-btn {
  background: none;
  border: none;
  color: #059669;
  cursor: pointer;
  font-weight: 500;
}

.otp-v-edit-btn:hover {
  color: #047857;
}

.otp-v-input-group {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 32px;
}

.otp-v-input {
  width: 48px;
  height: 48px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  outline: none;
  transition: all 0.2s;
}

.otp-v-input:focus {
  border-color: #059669;
  box-shadow: 0 0 0 2px rgba(5, 150, 105, 0.2);
}

.otp-v-input-active {
  border-color: #059669;
  box-shadow: 0 0 0 2px rgba(5, 150, 105, 0.2);
}

.otp-v-footer {
  text-align: center;
}

.otp-v-resend-text {
  color: #717173;
  margin-bottom: 16px;
  font-weight: 400;
}

.otp-v-resend-btn {
  background: none;
  border: none;
  color: #149c5b;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
}

.otp-v-resend-btn:hover {
  color: #047857;
}

.otp-v-verify-btn {
  width: 100%;
  padding: 12px;
  background-color: #059669;
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.otp-v-verify-btn:hover {
  background-color: #047857;
}

.otp-v-back-btn {
  background: none;
  border: none;
  color: #6b7280;
  margin-top: 16px;
  cursor: pointer;
}

.otp-v-back-btn:hover {
  color: #374151;
}

.signin-password-input-fieldset {
  width: 90% !important;
}

.konect-form-password-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 20px;
  /* align-items: center; */
}

.konect-signup-input-validation {
  color: #717173;
  font-size: 13px;
  font-weight: 400;
  margin-top: -15px;
  padding-left: 10px;
}

.konect-login-modal {
  font-size: 13px;
  font-weight: 400;
  color: #cacacb;
}

.forgot-password {
  text-align: right;
  padding-right: 20px;
}

@media (max-width: 480px) {
  .otp-v-card {
    margin: 16px;
    padding: 24px;
  }

  .otp-v-input {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 980px) {
  .signin-modal-img-container {
    display: none;
  }

  .konect-signin-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    overflow: auto;
  }

  .konect-signin-container>div:nth-child(2) {
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    align-items: center;
    padding-top: 50px;
  }

  .konect-form-password-container {
    width: 90%;
  }

  .konect-form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  /* .signin-input-fieldset {
    width: 100%;
  } */
  .konect-form-row {
    display: flex;
    gap: 20px;
    justify-content: center;
  }

}

@media (max-width:768px) {
  .konect-social-buttons {
    display: flex;
    justify-content: center;
    gap: 3px;
  }
}

@media (max-width:374px) {
  .konect-social-button {
    width: 100px;
  }
}